import React from "react";

import { Conferencias } from "./conferencias";
import { Visitas } from "./visitas";
import { Talleres } from "./talleres";
import { Extras } from "./extras";

export function Actividades() {
   
    return (
        <>  
            <section id="actividades" className="lg:mt-28 w-full h-screen flex bg-white lg:h-auto">
                <div className="w-full h-full bg-white  z-10 center m-auto ">

                    <h5 className="mt-2 m-auto text-center text-[10rem] diagonal-text font-semibold z-10 animate-fade animate-ease-linear
                    
                        lg:text-[4rem] 
                        md:text-black">Actividades</h5>


                   <div className="mt-8 h-[84%] ml-[6%] md:mt-[30%] xl:mt-[15%] flex flex-row lg:flex-col lg:ml-0 lg:gap-10">
                    <Conferencias/>
                    <Visitas/>
                    <Talleres/>
                    <Extras/>
                
                   </div>
                  
                
                    
                </div>
                
                
            </section>
        </>
    );
}
