import React, { useState, useEffect, useRef } from "react";
import { RxHamburgerMenu } from "react-icons/rx";
import logo from '../assets/images/logo.png';
import aareii from '../assets/images/aareii.svg';

export function Menu({ page }) {
    const [activeSection, setActiveSection] = useState('');
    const [barStyle, setBarStyle] = useState({});
    const [menuOpen, setMenuOpen] = useState(false);
    const navRef = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
            const sections = document.querySelectorAll('section');
            let currentSection = '';

            sections.forEach((section) => {
                const sectionTop = section.offsetTop;
                const sectionHeight = section.offsetHeight;
                if (window.scrollY >= sectionTop - sectionHeight / 2) {
                    currentSection = section.getAttribute('id');
                }
            });

            setActiveSection(currentSection);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        const nav = navRef.current;
        const activeItem = nav.querySelector(`li[data-section='${activeSection}']`);
        if (activeItem) {
            const itemRect = activeItem.getBoundingClientRect();
            const navRect = nav.getBoundingClientRect();
            const sectionLength = activeSection.length;
            setBarStyle({
                top: `${(itemRect.top - sectionLength * 4) - navRect.top - 35}px`, // Ajusta la posición vertical
                left: `${itemRect.left - navRect.left - 4}px`, // Ajusta la posición horizontal
                width: `1rem`, // Ancho de la barra
                height: `${4 + sectionLength * 0.2}rem`, // Ajusta la altura según la longitud del data-section
            });
        }
    }, [activeSection]);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    return (
        <>
            {/* Desktop Sidebar */}
            <div className="flex md:hidden ">
                <div className="w-[6%] h-screen bg-white shadow-2xl p-4 fixed z-50 md:w-[8%] ">
                    <div className="flex flex-col items-center relative">
                        <a href="#banner" className="flex">
                            <img src={logo} alt="Logo" className="lg:w-[130%]" />
                        </a>
                        <nav className="w-full" ref={navRef}>
                            <ul className="flex flex-col gap-4 transform rotate-360 text-sm text-center m-auto relative md:ml-[-2rem] md:text-[.8rem] md:gap-2 lg:ml-[-1.5rem] md:ml-[-1rem] xl:ml-[-1.5rem] lg:ml-[-1.5rem] 2xl:ml-[-1rem] 3xl:text-[.7rem]">
                                <div
                                    className="menu-bar bg-[#2CD3E2] absolute transition-all duration-300 ease-in-out ml-[-3.4rem] 2xl:ml-[-1.5rem] xl:ml-[-.5rem]"
                                    style={barStyle}
                                ></div>
                                <li data-section="banner" className="mb-10 xl:mb-4 md:mb-10"></li>
                                <li className={`p-2 m-8 mb-4 md:mb-4 2xl:mb-2 rounded ${activeSection === 'que-es' ? 'text-[#2CD3E2]' : ''}`} data-section="que-es">
                                    <a href="#que-es" className="hover:text-[#2CD3E2] block transform -rotate-90">NOSOTROS</a>
                                </li>
                                <li className={`p-2 m-8 md:mb-10 2xl:mb-4 rounded ${activeSection === 'pilares' ? 'text-[#2CD3E2]' : ''}`} data-section="pilares">
                                    <a href="#pilares" className="hover:text-[#2CD3E2] block transform -rotate-90">PILARES</a>
                                </li>
                                <li className={`p-2 m-8  md:mb-10 2xl:mb-5 rounded ${activeSection === 'disertantes' ? 'text-[#2CD3E2]' : ''}`} data-section="disertantes">
                                    <a href="#disertantes" className="hover:text-[#2CD3E2] block transform -rotate-90">DISERTANTES</a>
                                </li>
                                <li className={`p-2 m-8  md:mb-6 2xl:mb-4 rounded ${activeSection === 'actividades' ? 'text-[#2CD3E2]' : ''}`} data-section="actividades">
                                    <a href="#actividades" className="hover:text-[#2CD3E2] block transform -rotate-90 flex">ACTIVIDADES</a>
                                </li>
                                <li className={`p-2 m-8  md:mb-6 2xl:mb-4 rounded ${activeSection === 'participar' ? 'text-[#2CD3E2]' : ''}`} data-section="participar">
                                    <a href="#participar" className="hover:text-[#2CD3E2] block transform -rotate-90">PARTICIPAR</a>
                                </li>
                                <li className={`p-2 m-8  mb-[5rem] md:mb-8 lg:mb-[4rem] 2xl:mb-[3rem] rounded ${activeSection === 'sponsors' ? 'text-[#2CD3E2]' : ''}`} data-section="sponsors">
                                    <a href="#sponsors" className="hover:text-[#2CD3E2] block transform -rotate-90">SPONSORS</a>
                                </li>
                            </ul>
                        </nav>
                        <a href="https://aareii.org.ar/" className="w-[60%] bottom-0 absolute lg:w-[100%] xl:w-[120%] cursor-pointer" target="_blank">
                            <img src={aareii} alt="Logo" />
                        </a>
                    </div>
                </div>
            </div>

            {/* Mobile Hamburger Menu */}
            <div className="flex mmd:hidden fixed top-0 left-0 w-screen z-50  h-[3rem] ">
                <div className="w-screen flex justify-between p-4 pt-1 bg-white shadow-2xl backdrop-blur bg-white/30">
                    <a href="#banner" className="flex">
                        <img src={logo} alt="Logo" className="h-10" />
                    </a>
                    <button onClick={toggleMenu} className="text-3xl pt-2 text-black">
                        <RxHamburgerMenu />
                    </button>
                </div>
                {menuOpen && (
                    <div className="absolute top-0 left-0 w-full h-screen bg-color2 flex flex-col items-center justify-center z-40">
                        <nav>
                            <ul className="flex flex-col items-center gap-6 text-xl">
                                <li className={`p-2 ${activeSection === 'que-es' ? 'text-white' : ''}`} data-section="que-es">
                                    <a href="#que-es" onClick={toggleMenu} className="hover:text-white">NOSOTROS</a>
                                </li>
                                <li className={`p-2 ${activeSection === 'pilares' ? 'text-white' : ''}`} data-section="pilares">
                                    <a href="#pilares" onClick={toggleMenu} className="hover:text-white">PILARES</a>
                                </li>
                                <li className={`p-2 ${activeSection === 'disertantes' ? 'text-white' : ''}`} data-section="disertantes">
                                    <a href="#disertantes" onClick={toggleMenu} className="hover:text-white">DISERTANTES</a>
                                </li>
                                <li className={`p-2 ${activeSection === 'actividades' ? 'text-white' : ''}`} data-section="actividades">
                                    <a href="#actividades" onClick={toggleMenu} className="hover:text-white">ACTIVIDADES</a>
                                </li>
                                <li className={`p-2 ${activeSection === 'participar' ? 'text-white' : ''}`} data-section="participar">
                                    <a href="#participar" onClick={toggleMenu} className="hover:text-white">PARTICIPAR</a>
                                </li>
                                <li className={`p-2 ${activeSection === 'sponsors' ? 'text-white' : ''}`} data-section="sponsors">
                                    <a href="#sponsors" onClick={toggleMenu} className="hover:text-white">SPONSORS</a>
                                </li>
                            </ul>
                        </nav>
                        <a href="https://aareii.org.ar/" className="mt-6">
                            <img src={aareii} alt="Logo" className="h-14" />
                        </a>
                    </div>
                )}
            </div>
        </>
    );
}
