import React from "react";
import circle from '../assets/images/circle.svg';
import pilar1 from '../assets/images/pilar1.svg';
import pilar2 from '../assets/images/pilar2.svg';
import pilar3 from '../assets/images/pilar3.svg';

export function Pilares() {
    return (
        <>  
            <section id="pilares" className="w-screen lg:h-full min-h-screen bg-white mt-[5rem] flex flex-col justify-center items-center">

                <h5 className="mt-2 m-auto text-center text-[12rem] diagonal-text font-semibold z-10 animate-fade animate-ease-linear
                    
                        lg:text-[4.5rem] 
                        md:text-black">
                    Pilares
                </h5>

                <div className="flex flex-wrap justify-center items-start w-full max-w-screen-xl mt-8 gap-8 ml-20 lg:ml-0 lg:w-[95%]">

                    <div className=" w-[30%] lg:w-[30%] md:w-[80%] sm:w-full flex flex-col items-center">
                        <img src={pilar1} alt="Pilar 1" className="w-[45%] opacity-[0.1] lg:w-[65%] mx-auto"/>

                        <h2 className="text-[4rem] color1 font-semibold mt-2 ">Pilar N°1</h2>
                        <h5 className="text-2xl font-semibold color4 mt-2 text-center">“La revolución de la colaboración hombre-máquina“</h5>
                        <p className="text-lg font-semibold mt-4 text-center w-10/12">
                            ¿Cómo la colaboración eficiente entre humanos y tecnología puede impulsar la innovación en la industria?
                        </p>
                    </div>

                    <div className=" w-[30%] lg:w-[30%] md:w-[80%] sm:w-full flex flex-col items-center lg:mt-16">

                        <img src={pilar2} alt="Pilar 2" className="w-[40%] opacity-[0.1] lg:w-[55%] mx-auto "/>

                        <h2 className="text-[4rem] color1 font-semibold mt-2 ">Pilar N°2</h2>
                        <h5 className="text-2xl font-semibold color4 mt-2 text-center">“Liderazgo Emocionalmente Inteligente“</h5>
                        <p className="text-lg font-semibold mt-4 text-center w-10/12">
                            ¿Cómo la inteligencia emocional contribuye al liderazgo efectivo en el entorno industrial?
                        </p>
                    </div>

                    <div className="w-[30%] lg:w-[30%] md:w-[80%] sm:w-full flex flex-col items-center lg:mt-16">
                        <img src={pilar3} alt="Pilar 3" className="w-[47%] opacity-[0.1] lg:w-[55%] mx-auto"/>
                        <h2 className="text-[4rem] color1 font-semibold mt-2 ">Pilar N°3</h2>
                        <h5 className="text-2xl font-semibold color4 mt-2 text-center">“Innovación y Emprendimiento“</h5>
                        <p className="text-lg font-semibold mt-4 text-center w-10/12">
                            ¿Cómo la innovación y el emprendimiento impulsan la transformación en la Ingeniería Industrial?
                        </p>
                    </div>
                </div>

                <div className="relative w-full flex justify-center items-center mt-16">
                    <img src={circle} alt="Circle Animation" className="animate-bounce absolute left-4 w-[10rem] h-[10rem] mx-4 lg:w-[7rem] lg:h-[7rem] lg:hidden" />

                  
                </div>
            </section>
        </>
    );
}
