import React from "react";
import cube from '../assets/images/cube.svg';
import sponsor1 from '../assets/sponsors/Colaborador Fundación YPF.png';
import sponsor2 from '../assets/sponsors/Sponsor ONELITE PHARMA.png';
import sponsor3 from '../assets/sponsors/Sponsor TASSAROLI.png';
import sponsor4 from '../assets/sponsors/Sponsor SUDAMERICANA DE BEBIDAS.png'
import sponsor5 from '../assets/sponsors/Sponsor TR3BOL.png';

export function Sponsors() {
    const sponsors = [
        { id: 1, name: "Colaborador Fundación YPF", img: sponsor1 },
        { id: 2, name: "ONELITE PHARMA", img: sponsor2 },
        { id: 3, name: "TASSAROLI", img: sponsor3 }, 
        { id: 4, name: "SUDAMERICANA DE BEBIDAS" , img: sponsor4 },
        { id: 5, name: "TR3BOL ARGENTINA", img: sponsor5 },
       
    ];

    return (
        <>  
            <section id="sponsors" className="min-h-screen w-full flex bg-white md:h-[65rem]">
                <div className="w-full h-screen bg-white z-10 center m-auto mt-[5rem]">
                    
                    <h5 className="mt-2 m-auto text-center text-[10rem] diagonal-text font-semibold z-10 animate-fade animate-ease-linear
                    
                        lg:text-[4rem] 
                        md:text-black">Sponsors</h5>

                    <div className="w-[90%] mx-auto ml-[7%] flex md:grid col-auto row-auto gap-6 mt-2 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 md:w-[95%] md:ml-0 md:mt-[8rem]">
                        {sponsors.map((sponsor) => (
                            <div key={sponsor.id} className="m-auto w-[30%] md:w-[70%]">
                                <img
                                    src={sponsor.img}
                                    alt={sponsor.name}
                                    className="m-auto w-[20rem] mxl:p-6 md:w-full object-cover animate-fade animate-ease-linear"
                                />
                            </div>
                        ))}
                    </div>

                    <a href="https://wa.me/5492604513825?text=Hola%2C%20me%20gustar%C3%ADa%20ser%20Sponsor%20del%20." target="_blank" className="mt-8  left-[42%] absolute text-2xl font-semibold bg-color2 p-4 text-white hover:border-2 hover:bg-violet-600 md:left-0 md:right-0 md:w-[70%] md:mx-auto">
                        Quiero ser Sponsor
                    </a>
                       
                </div>
            </section>
        </>
    );
}
