import React from "react";

import banner from '../assets/images/banner.jpg';
import isologo from '../assets/images/isologo.png';
import isologo2 from '../assets/images/isologo2.png';

export function Banner() {
    return (
        <>  
            <section id="banner" className="w-screen h-screen flex animate-[pulse_3s_ease-in-out]">
                <div className="w-[45%] h-full bg-white z-10 lg:hidden">
                    
                </div>
                <div className="w-[55%] min-h-screen bg-gray-100 lg:w-full">
                    <img src={isologo} alt="" className="lg:hidden z-10 absolute w-[65%] left-0 right-0 m-auto mt-[2%] lg:mt-[45%] lg:w-[85%]" />

                    <img src={isologo2} alt="" className="hidden lg:block z-10 absolute w-[65%] left-0 right-0 m-auto mt-[5%] md:mt-[45%] md:w-[85%]" />

                    <p className="z-30 text-[1.4rem] absolute left-[-33%] right-0 mt-[37%] color6 font-semibold 3xl:text-[1.2rem] lg:left-0 lg:text-white lg:mt-[47%] md:mt-[115%]">
                        <span className="font-bold">25, 26 y 27 de Octubre</span>
                        <br />
                        “Emotividad que inspira,<br /> racionalidad que convence”</p>

                    <div className="w-[55%] lg:w-full h-screen absolute z-1 bg-gradient-to-r from-blue-600 to-cyan-200 opacity-[0.7] lg:opacity-[0.8]  lg:from-blue-400 lg:to-sky-500"></div>
                    
                    <img src={banner} alt=""  className="h-screen w-full object-cover "/>
                </div>
                
            </section>
        </>
    );
}
