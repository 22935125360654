import React from "react";

import hoja from "../assets/images/hoja.jpg";
import { IoMdDownload } from "react-icons/io";

export function Participar() {
    return (
        <>  
            <section id="participar" className="h-screen w-full flex bg-white md:h-[115rem]">
                <div className="w-full h-full bg-white z-10 center m-auto mt-[5rem] ">
                    <img src={hoja} alt="" className="z-1 absolute mt-[-5%] h-[screen] md:h-[115rem] w-full object-cover" />
                    
                    
                    <h5 className="mt-2 m-auto text-center text-[10rem] diagonal-text font-semibold z-10 animate-fade animate-ease-linear absolute left-0 right-0
                    
                        lg:text-[4rem] 
                        md:text-black">Participar</h5>

                    <div className="mt-[18%] flex ml-[20%] gap-6 z-10 absolute 2xl:ml-[7%] 
                        md:flex-col md:ml-auto md:right-0 md:left-0 md:items-center md:mx-auto md:mt-[25%]">
                        <div className="w-[20%] 2xl:w-[23%] md:w-[80%]">
                            <h4 className="h-[7rem] bg-color3 p-6 font-bold text-2xl pt-10">¿Qué hago?</h4>
                            <p className="font-semibold mt-4">Realizar un ensayo con base en uno o más pilares del evento</p>
                        </div>

                        <div className="w-[20%] 2xl:w-[23%] md:w-[80%]">
                            <h4 className="h-[7rem] bg-color3 p-6 font-bold text-2xl ">¿Quiénes los evalúan?</h4>
                            <p className="font-semibold mt-4">Equipo del Departamento de Ingeniería Industrial de la UTN FRSR</p>
                        </div>

                        <div className="w-[20%] 2xl:w-[23%] md:w-[80%]">
                            <h4 className="h-[7rem] bg-color3 p-6 font-bold text-2xl pt-10">¿Cómo se evalúa?</h4>
                            <p className="font-semibold mt-4">Introducción, desarrollo, conclusión, coherencia, ortografía, investigación</p>
                        </div>

                        <div className="w-[20%] 2xl:w-[23%] md:w-[80%] ">
                            <h4 className="h-[7rem] bg-color3 p-6 font-bold text-2xl">Beneficios al asistente</h4>
                            <p className="font-semibold mt-4">Merchandising <br />Alojamiento <br /> Visitas técnicas, conferencias, talleres y actividades de integración <br /> Desayunos, cena de gala y fiesta de bienvenida<br />Seguro</p>
                        </div>
                    </div>
              
                    <a href="https://gestion.aareii.org.ar/login" target="_blanck" className="z-30 mt-[30%] left-[42%] absolute text-2xl font-semibold bg-color2 p-4 text-white hover:border-2 hover:bg-violet-600 2xl:mt-[35%] xl:mt-[40%]
                    md:mt-[300%] md:left-0 md:right-0 md:w-[80%] md:mx-auto">Subir mi Ensayo</a>
                      
                  
                    
                    <a href="/ensayos_de_ejemplo.zip" className="z-10 absolute mt-[36%] left-0 right-0 text-xl font-semibold 2xl:mt-[41%] xl:mt-[50%] md:mt-[330%] md:w-[90%] md:mx-auto hover:text-blue-400" >Descarga los ensayos de ejemplo y las bases y condiciones</a>

                    <i className="z-10 absolute mt-[35.5%] left-[30%] text-4xl text-black animate-jump animate-infinite animate-duration-[3000ms] animate-ease-in animate-normal 2xl:mt-[41%] 2xl:left-[23%] xl:mt-[50%] xl:left-[15%] md:mt-[320%] md:left-[45%] md:animate-none "><IoMdDownload /></i>
                    
                    <div className=" gap-8 z-10 absolute mt-[40%] text-xl left-0 right-0 2xl:mt-[45%] 2xl:text-[1rem] xl:mt-[55%] xl:text-[.9rem] xl:left-10 md:mt-[360%] md:flex-col md:flex md:left-0">

                        <a href="https://semeii.com.ar/files/samples/Sample1-Welschen_Constanza-Organizaciones_Agiles-42732273.pdf" className="mx-6 hover:text-blue-400 2xl:mx-4 xl:mx-2" target="_blank">
                            Ej 1: Organizaciones ágiles
                        </a>

                        <a href="https://semeii.com.ar/files/samples/Sample2-Filipuzzi_María_Clara-Economía_del_Conocimiento-41119764.pdf" className="mx-6 hover:text-blue-400 2xl:mx-4 xl:mx-2" target="_blank">Ej 2: Economía del Conocimiento</a>

                        <a href="https://semeii.com.ar/files/samples/Sample3-Pagano_De_Boni_Zaro_Estefano-Neurocoaching-40070100.pdf" className="mx-6 hover:text-blue-400 2xl:mx-4 xl:mx-2" target="_blank">Ej 3: Neurocoaching</a>

                        <a href="https://semeii.com.ar/files/Bases_SEMEII 2024.pdf" className="mx-6 hover:text-blue-400 2xl:mx-4 xl:mx-2" target="_blank">Bases y Condiciones</a>

                        <a href="https://semeii.com.ar/files/Hoja_Bases_Condiciones_Ensayo.docx" className="mx-6 hover:text-blue-400 2xl:mx-4 xl:mx-2" download>Hoja de Ensayo</a>

                    </div>
                    
                    
                </div>
                
                
                
            </section>
        </>
    );
}
