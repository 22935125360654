import React from "react";

import './App.css';
import { Menu} from './components/menu';
import { Banner } from './components/banner';
import { Nosotros } from "./components/nosotros";
import { Pilares } from "./components/pilares";
import { Disertantes } from "./components/disertantes";
import { Actividades } from "./components/actividades";
import { Sponsors } from "./components/sponsors";
import { Participar } from "./components/participar";
import { Contacto } from "./components/contacto";

function App() {
  
    return (
        <div className="App">
          
          <Menu/>
          <Banner />
          <Nosotros/>
          <Pilares/>
          <Disertantes/>
          <Actividades/>
          <Participar/>
          <Sponsors/>
          <Contacto/>
         
        </div>
    );
}
export default App;
