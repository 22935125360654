import React, { useState, useEffect, useRef } from 'react';
import { MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from "react-icons/md";
import { CiLinkedin } from "react-icons/ci";

import disertantes from '../disertantes.json';

import image2 from '../assets/disertantes/pabloreyes.jpg';
import image3 from '../assets/disertantes/mariananunes.jpg';
import image4 from '../assets/disertantes/tassaroli.jpg';
import image5 from '../assets/disertantes/belenfernandez.jpeg';
import image6 from '../assets/disertantes/simonassi.jpeg';
import image7 from '../assets/disertantes/alexisatem.jpeg';
import image8 from '../assets/disertantes/ornellabianchi.jpg';
import image9 from '../assets/disertantes/catalinatoro.jpg';
import image10 from '../assets/disertantes/dariorojas.jpg';
import image11 from '../assets/disertantes/valeriajuarez.jpeg';
import image12 from '../assets/disertantes/angelquiles.jpg';
import image20 from '../assets/disertantes/guillermoquintares.jpg';
import image16 from '../assets/disertantes/edgardolopez.jpg';
import image17 from '../assets/disertantes/silvioaranguez.jpg';
import image18 from '../assets/disertantes/georgina.jpg';
import image19 from '../assets/disertantes/carloszorzoli.jpg';
import image21 from '../assets/disertantes/agustincerutti.jpeg';
import image22 from '../assets/disertantes/ramirogarcia.jpeg';
//import image15 from '../assets/disertantes/carlacampoy.jpg'; // campoy

const imageMap = {
   
    "pabloreyes": image2,
    "mariananunes": image3,
    "tassaroli": image4,
    "belenfernandez": image5,
    "simonassi": image6,
    "alexisatem": image7,
    "ornellabianchi": image8,
    "catalinatoro": image9,
    "dariorojas": image10,
    "valeriajuarez": image11,
    "angelquiles": image12,
    "guillermoquintares": image20,
    "edgardolopez": image16,
    "silvioaranguez": image17,
    "georgina": image18,
    "carloszorzoli": image19,
    "agustincerutti": image21,
    "ramirogarcia": image22

};

export const ImageGallerysm = () => {
    const [selectedIndex, setSelectedIndex] = useState(disertantes.length * 2); // Start in the middle of the duplicated list
    const galleryRef = useRef(null);
    const items = [...disertantes, ...disertantes, ...disertantes]; // Triple the items

    const updateScrollPosition = (index, behavior = 'smooth') => {
        const gallery = galleryRef.current;
        const children = Array.from(gallery.children);
        const selectedElement = children[index];
        const galleryWidth = gallery.clientWidth;
        const selectedElementWidth = selectedElement.clientWidth;
        const scrollPosition = selectedElement.offsetLeft - (galleryWidth / 2 - selectedElementWidth / 2);
        gallery.scrollTo({
            left: scrollPosition,
            behavior
        });
    };

    useEffect(() => {
        updateScrollPosition(selectedIndex, 'auto');
    }, []);

    useEffect(() => {
        if (selectedIndex >= disertantes.length * 2 || selectedIndex < disertantes.length) {
            const newIndex = selectedIndex >= disertantes.length * 2 ? disertantes.length : disertantes.length * 2 - 1;
            setTimeout(() => {
                updateScrollPosition(newIndex, 'auto');
                setSelectedIndex(newIndex);
            }, 300); // This should match your CSS transition duration
        } else {
            updateScrollPosition(selectedIndex);
        }
    }, [selectedIndex]);

    const handlePrevClick = () => {
        setSelectedIndex(prevIndex => prevIndex - 1);
    };

    const handleNextClick = () => {
        setSelectedIndex(prevIndex => prevIndex + 1);
    };

    return (
        <div className="relative flex flex-col items-center m-auto mt-[20%] ">
            <div className="flex items-center w-full mb-4 justify-center relative">
                <button
                    onClick={handlePrevClick}
                    className="z-30 absolute left-1  top-[83%] color1 p-2 rounded-full"
                >
                    <MdOutlineKeyboardArrowLeft className='text-6xl' />
                </button>
                <div ref={galleryRef} className="overflow-x-hidden flex w-full overflow-x-hidden snap-center overflow-y-hidden ">

                    {items.map((person, index) => (
                        <div
                            key={index}
                            className={` z-20 relative flex-shrink-0 cursor-pointer mx-2 transition-transform duration-300 ${
                                selectedIndex === index ? 'transform scale-105' : ''
                            }`}
                            onClick={() => setSelectedIndex(index)}
                            style={{ width: selectedIndex === index ? '55%' : '15%', height: selectedIndex === index ? '27rem' : '21rem', transition: 'height 0.3s ease' }}
                        >
                            <img
                                src={imageMap[person.image]}
                                alt={person.name}
                                className={`overflow-x-hidden object-cover h-full w-full transition-all duration-300 grayscale ${
                                    selectedIndex === index ? 'grayscale-0' : ''
                                }`}
                            />
                        </div>
                    ))}
                </div>

                <button
                    onClick={handleNextClick}
                    className="overflow-x-hidden z-30 absolute right-2 top-[83%] color1 p-2 rounded-full"
                >
                    <MdOutlineKeyboardArrowRight className='text-6xl' />
                </button>
            </div>


            <div className="absolute bg-white p-4 text-center mt-[120%] border-2 border-[#625FA7] w-[99%] h-[28rem] flex flex-col gap-4 items-center justify-center ">

                <a href={disertantes[selectedIndex % disertantes.length].linkedin} className=" text-[3rem] color7">
                    <CiLinkedin />
                </a>

                <h2 className="text-2xl  font-bold w-[90%]">{disertantes[selectedIndex % disertantes.length].name}
                    <br />
                    <span className='text-sm text-gray-600'>
                        {disertantes[selectedIndex % disertantes.length].title}
                    </span>
                </h2>

              
                <p className=" mr-[2%] text-[.8rem] text-gray-600 text-justify px-4 sm:px-0 w-[95%] ">{disertantes[selectedIndex % disertantes.length].description}</p>
            </div>
        </div>
    );
};


