import React from "react";
import contacto from "../assets/images/contacto.webp";
import { FaInstagram } from "react-icons/fa";
import { CiMail } from "react-icons/ci";
import { FiPhone } from "react-icons/fi";


export function Contacto() {
    return (
        <section id="contacto" className="relative h-[30rem] w-full flex items-center justify-center bg-cover bg-center" style={{ backgroundImage: `url(${contacto})` }}>
            <div className="absolute inset-0 bg-gradient-to-r from-blue-600 to-cyan-200 opacity-70"></div>

            <div className="w-full relative z-20 text-center mt-[15%] left-0 right-0">
                <h3 className="text-white md:text-2xl text-4xl lg:text-4xl font-bold mb-8 mx-auto 3xl:text-3xl">“Emotividad que inspira, racionalidad que convence”</h3>

                <div className="flex md:flex-col flex-row justify-center items-center gap-6 mb-12 ml-6 lg:ml-0 ">
                    <a href="https://instagram.com/semeii_" className="text-white text-3xl flex items-center gap-2 hover:text-blue-200" target="_blank" rel="noopener noreferrer">
                        <FaInstagram />
                        <p className="2xl:text-lg text-xl">@semeii_</p>
                    </a>   

                    <a href="mailto:presidente@semeii.com.ar" className="text-white text-3xl flex items-center gap-2 hover:text-blue-200">
                        <CiMail />
                        <p className="2xl:text-lg text-xl">presidente@semeii.com.ar</p>
                    </a>     

                    <a href="mailto:coordinacion@semeii.com.ar" className="text-white text-3xl flex items-center gap-2 hover:text-blue-200">
                        <CiMail />
                        <p className="2xl:text-lg text-xl">coordinacion@semeii.com.ar</p>
                    </a>   

                    <a href="https://wa.me/5492604513825" target="_blank" className="text-white text-3xl flex items-center gap-2 hover:text-blue-200">
                        <FiPhone />
                        <p className="2xl:text-lg text-xl">+54 9 260 4513825</p>
                    </a>               
                </div>

                <div className="text-black font-bold bg-white w-full p-4 bottom-0 mt-[10%] md:mt-[22%] ">
                    <a href="https://www.linkedin.com/in/martinlaloggia/"  target="_blank" className="hover:text-blue-400 text-sm">Desarrollado por
                        <span> Martín La Loggia</span>
                    </a>
               
                </div>
            </div>
        </section>
    );
}
