import React from "react";
import nosotros1 from '../assets/images/nosotros1.jpg';
import nosotros2 from '../assets/images/nosotros2.jpg';
import circle from '../assets/images/circle.svg';
import rio from '../assets/images/rio.svg';
import mountain from '../assets/images/mountain.svg';
export function Nosotros() {
    return (
        <>  
            <section id="que-es" className=" w-full min-h-screen flex bg-[#FAF0F8] lg:h-[60rem]">
                <img src={mountain} alt=""  className="w-full absolute left-0 right-0 bottom-0 z-20 lg:hidden"/>

                <div className="w-full  bg-[#FAF0F8]  z-10 center m-auto mt-[10rem] ">

                <img src={rio} alt=""  className="w-full absolute left-20 right-0 mt-[5%] h-full
                        lg:mt-[170%] lg:left-40 sm:hidden "/>

                    <h5 className="mt-[-8%] absolute m-auto left-0  ml-[12%] text-[12rem] diagonal-text font-semibold z-10 animate-fade animate-ease-linear
                        2xl:ml-0 2xl:right-0 2xl:mt-[-12%] 
                        lg:text-[4.5rem] lg:mt-[-9%] 
                        md:text-black
                    ">Nosotros</h5>


                    <img src={nosotros2} alt="" className="m-auto absolute right-4 float-right rounded-full w-[20rem] h-[20rem] object-cover animate-fade animate-ease-linear 
                        2xl:w-[10rem] 2xl:h-[10rem] 2xl:right-0 
                        lg:w-[7rem] lg:h-[7rem] 
                        xl:mt-[-7rem] xl:mr-[2%]
                        
                    " />

                    <h2 className="text-[5rem] m-auto mt-[5rem]  center color3 font-semibold z-50 lg:text-[2.5rem]">¿Qué Es <span className="color2" >SemEII?</span></h2>

                    <h5 className="text-3xl mt-4 w-[35%] m-auto font-semibold 2xl:w-[45%] 2xl:text-2xl lg:text-[1.2rem] lg:w-[85%]">Seminario de Estudiantes de Ingeniería Industrial y carreras afines</h5>

                    <p className="text-xl mt-4 w-[33%] m-auto relative z-20 left-0 right-0 font-semibold justify  2xl:w-[45%] 2xl:text-[1rem] lg:text-[.8rem] lg:w-[85%] lg:text-justify">
                    El Seminario de Estudiantes de Ingeniería Industrial y carreras afines, es el evento más exclusivo de AArEII (Asociación Argentina de Estudiantes de Ingeniería Industrial) que incentiva a estudiantes y jóvenes profesionales a fortalecer habilidades personales que serán de utilidad para la vida profesional. En un marco de tres jornadas consecutivas, se desarrollarán diversas actividades académicas como conferencias profesionales y magistrales, talleres interactivos y visitas técnicas.
                    </p>
                   

                    
                         
                    <img src={nosotros1} alt="" className="m-auto relative left-0 ml-[10%] rounded-full w-[20rem] h-[20rem] object-cover animate-fade animate-ease-linear 
                        2xl:w-[13rem] 2xl:h-[13rem] 
                        lg:w-[8rem] lg:h-[8rem] 
                        lg:mt-4
                        
                    " />


                    <img src={circle} alt="" className="m-auto absolute left-[80%]  mt-[10%] w-[20rem] h-[20rem] object-cover animate-fade animate-ease-linear 
                        2xl:w-[15rem] 2xl:h-[15rem] 2xl:mt-[10%] 
                        lg:left-[82%] lg:mt-[25%] sm:hidden" />

                    <img src={circle} alt="" className="m-auto absolute left-0  mt-[-10%] w-[15rem] h-[15rem] object-cover animate-fade animate-ease-linear 
                        2xl:w-[12rem] 2xl:h-[12rem] 2xl:mt-[-15%] 
                        lg:left-[-7rem]" />
                    
                </div>
                
                
            </section>
        </>
    );
}
